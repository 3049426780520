import { SiteContext } from "@organic-return/foundation-gatsby-plugin-drupal/src/components/common/SiteContext"
import { IListingSummary } from "@organic-return/foundation-react/src/lib/Listing"
import { Sort } from "@organic-return/foundation-react/src/components/Search/Sort"
import React from "react"
import {
  Check,
  Choose,
  Text,
} from "@organic-return/foundation-react/src/components/Search/Filter"
import { ISearchContext } from "@organic-return/foundation-react/src/components/Search/SearchContext"
import { EListingCardLayouts, EListingVitalsLayout } from "@organic-return/foundation-react/src/components/Listing"
import { Link, graphql, useStaticQuery } from "gatsby"
import { EActivationBreakpoint, FilterDropdown, IFilterDropdown } from "@organic-return/foundation-react/src/components/Search/FilterDropdown"
import { IAgent } from "@organic-return/foundation-react/src/lib/Contact/types"
import { FooterLabeledColumns } from "@organic-return/foundation-react/src/components/Footer/FooterLabeledColumns"
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaPinterest, FaTiktok, FaTwitter, FaVimeo, FaYoutube } from "react-icons/fa"
import { ISiteMenuItem } from "@organic-return/foundation-gatsby-plugin-drupal/src/lib/utils"

export const SiteContextProvider: React.FC = ({ children }: any) => {

  const data = useStaticQuery(graphql`
    {
      config: drupalSiteConfiguration {
        footerContent1
        copyright
        listingsSearchPath
        headshot {
          url
        }
        footerLogo {
				  url
        }        
        columnsTitles
        footerSecondaryLogo {
          icon {
              url
          }
        }
        socialLinks {
          facebook {
            url
            title
          }
          instagram {
            url
            title
          }
          linkedIn {
            url
            title
          }
          twitter {
            url
            title
          }
          youTube {
            url
            title
          }
          vimeo {
            url
            title
          }
          pinterest {
            url
            title
          }
        }
      }
    }
  `)

  const selectStyles = {
    control: (base: object) => ({
      ...base,
      borderColor: "transparent",
      background: "transparent",
      borderBottomColor: "var(--color-composite-text)",
      borderRadius: 0,
    }),
    option: (base: object) => ({
      ...base,
      color: "var(--color-body-text)",
    }),
    singleValue: (base: any) => ({
      ...base,
      color: "var(--color-composite-text)",
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      paddingRight: "0px",
      color: "var(--color-composite-text)",
    }),
    indicatorSeparator: (base: object) => ({
      ...base,
      display: "none",
    }),
    placeholder: (base: object) => ({
      ...base,
      color: "var(--color-composite-text)",
      whiteSpace: "nowrap",
    }),
    valueContainer: (base: object) => ({
      ...base,
      paddingLeft: 0,
    }),
    menu: (base: object) => ({
      ...base,
      zIndex: 11,
    }),
  }

  const selectStylesCompact = {
    control: (base: object) => ({
      ...base,
      background: "transparent",
      borderColor: "#AEB3B7",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      borderRadius: 4,
    }),
    option: (base: object) => ({
      ...base,
      color: "var(--color-body-text)",
    }),
    singleValue: (base: any) => ({
      ...base,
      color: "var(--color-body-text)",
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      paddingRight: "0px",
      color: "var(--color-body-text)",
    }),
    indicatorSeparator: (base: object) => ({
      ...base,
      display: "none",
    }),
    placeholder: (base: object) => ({
      ...base,
      color: "#808080",
      whiteSpace: "nowrap",
      fontSize: "1rem",
    }),
    valueContainer: (base: object) => ({
      ...base,
      paddingLeft: 0,
      fontSize: "1rem",
    }),
  }

  const priceFilters = function (context: ISearchContext, style?: any) {
    return (
      <>
        <Choose
          context={context}
          label="Min Price"
          placeholder="Min Price"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.price || 0) >= parseInt(vstr) || false
          }}
          param="minPrice"
          resolver={arg => arg.price}
          reactSelectStyles={style ?? selectStyles}
          options={[
            { label: "> $200k", value: "200000" },
            { label: "> $400k", value: "400000" },
            { label: "> $600k", value: "600000" },
            { label: "> $800k", value: "800000" },
            { label: "> $1M", value: "1000000" },
            { label: "> $3M", value: "3000000" },
            { label: "> $5M", value: "5000000" },
            { label: "> $7M", value: "7000000" },
            { label: "> $10M", value: "10000000" },
            { label: "> $15M", value: "15000000" },
          ]}
        />
        <Choose
          context={context}
          label="Max Price"
          placeholder="Max Price"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.price || 0) <= parseInt(vstr) || false
          }}
          param="maxPrice"
          resolver={arg => arg.price}
          reactSelectStyles={style ?? selectStyles}
          options={[
            { label: "< $200k", value: "200000" },
            { label: "< $400k", value: "400000" },
            { label: "< $600k", value: "600000" },
            { label: "< $800k", value: "800000" },
            { label: "< $1M", value: "1000000" },
            { label: "< $3M", value: "3000000" },
            { label: "< $5M", value: "5000000" },
            { label: "< $7M", value: "7000000" },
            { label: "< $10M", value: "10000000" },
            { label: "< $15M", value: "15000000" },
          ]}
        />
      </>
    )
  }

  /**
   * Standard filters for global Real Estate listing search.
   */
  const listingStandardFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          placeholder="Location"
          label="Location"
          param="address.city"
          resolver={arg => arg.address.city}
          match="any"
          reactSelectStyles={selectStyles}
          showAllEntriesOption={true}
          options={[
            {
              value: "Aspen",
              label: "Aspen",
            },
            {
              value: "Snowmass Village",
              label: "Snowmass Village",
            },
            {
              value: "Basalt",
              label: "Basalt",
            },
            {
              value: "Carbondale",
              label: "Carbondale",
            },
            {
              value: "Snowmass",
              label: "Snowmass",
            },
            {
              value: "Woody Creek",
              label: "Woody Creek",
            },
            {
              value: "Glenwood Springs",
              label: "Glenwood Springs",
            },
          ]}
        />
        <Choose
          context={context}
          placeholder="Type"
          label="Type"
          param="type"
          resolver={arg => arg.type}
          sort="count"
          match="any"
          reactSelectStyles={selectStyles}
        />
        <Choose
          context={context}
          placeholder="Sub-Type"
          label="Sub-Type"
          param="subType"
          resolver={arg => arg.subType}
          sort="count"
          match="any"
          reactSelectStyles={selectStyles}
        />
        {priceFilters(context)}
        <Choose
          context={context}
          placeholder="Features"
          label="Features"
          param="features"
          resolver={arg => arg.features}
          sort="count"
          match="all"
          reactSelectStyles={selectStyles}
        />
        <Choose
          context={context}
          placeholder="Status"
          label="status"
          param="statuses"
          resolver={arg => arg.status}
          match="any"
          className="hidden"
        />
      </>
    )
  }
  /**
   * Standard filters for global Real Estate listing search.
   */
  const listingEntryFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          placeholder="Location"
          label="Location"
          param="address.city"
          resolver={arg => arg.address.city}
          match="one"
          reactSelectStyles={selectStyles}
          showAllEntriesOption={true}
          options={[
            {
              value: "Aspen",
              label: "Aspen",
            },
            {
              value: "Snowmass Village",
              label: "Snowmass Village",
            },
            {
              value: "Basalt",
              label: "Basalt",
            },
            {
              value: "Carbondale",
              label: "Carbondale",
            },
            {
              value: "Snowmass",
              label: "Snowmass",
            },
            {
              value: "Woody Creek",
              label: "Woody Creek",
            },
            {
              value: "Glenwood Springs",
              label: "Glenwood Springs",
            },
          ]}
        />
        <Choose
          context={context}
          placeholder="Type"
          label="Type"
          param="type"
          resolver={arg => arg.subType}
          sort="count"
          match="one"
          reactSelectStyles={selectStyles}
          options={[
            {
              value: "Residential",
              label: "Residential",
            },
            {
              value: "Land",
              label: "Land",
            },
            {
              value: "Fractional",
              label: "Fractional",
            },
          ]}
        />
        {priceFilters(context)}
      </>
    )
  }

  const orFilters = function (context: ISearchContext) {
    return (
      <Text
        context={context}
        label="MLS# / Keyword"
        placeholder="MLS# / Keyword"
        className="shrink"
        inputClassName="rounded-none bg-transparent border-b-composite-text text-composite-text placeholder:text-composite-text px-0 min-w-[11ch] max-w-[13ch]"
        param="text"
        resolver={(arg: any, text) => {
          return true
        }}
      />
    )
  }

  /**
   * Standard filters for community page Real Estate listing search.
   */
  const communityStandardFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          placeholder="Type"
          label="Type"
          param="type"
          resolver={arg => arg.subType}
          sort="count"
          reactSelectStyles={selectStyles}
          match="one"
        />
        {priceFilters(context)}
        <Choose
          context={context}
          placeholder="Features"
          label="Features"
          param="features"
          resolver={arg => arg.features}
          sort="count"
          match="one"
          reactSelectStyles={selectStyles}
        />
      </>
    )
  }

  /**
   * Sorts to be used in all Listing (real estate) searches
   */
  const listingSort = function (context: ISearchContext) {
    return (
      <Sort
        context={context}
        className="text-center py-1"
        label="Sort by: "
        sorts={[
          {
            label: "Price: $$$ to $",
            key: "priceDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.price || 0) - (l0.price || 0)
            },
          },
          {
            label: "Price: $ to $$$",
            key: "priceASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l0.price || 0) - (l1.price || 0)
            },
          },
          {
            label: "Bedrooms: High to Low",
            key: "bedsDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.beds || 0) - (l0.beds || 0)
            },
          },
          {
            label: "Bedrooms: Low to High",
            key: "bedsASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l0.beds || 0) - (l1.beds || 0)
            },
          },
          {
            label: "Sq Ft: High to Low",
            key: "sqftDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.sqft || 0) - (l0.sqft || 0)
            },
          },
          {
            label: "Sq Ft: Low to High",
            key: "sqftASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l0.sqft || 0) - (l1.sqft || 0)
            },
          },
          {
            label: "Lot Size: High to Low",
            key: "lotDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.lotacres || 0) - (l0.lotacres || 0)
            },
          },
          {
            label: "Lot Size: Low to High",
            key: "lotASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l0.lotacres || 0) - (l1.lotacres || 0)
            },
          },
          {
            label: "Last Updated",
            key: "statusTSDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.statusTS || "").localeCompare(l0.statusTS || "")
            },
          },
        ]}
      />
    )
  }

  /**
   * Middle filter for real estate listing search
   */
  const listingMiddleFilters = function (context: ISearchContext) {
    return (
      <>
        <Text
          context={context}
          placeholder="MLS#, City, Zip, Area"
          param="text"
          label="MLS#, City, Zip, Area"
          inputClassName="bg-transparent text-composite-text border-composite-text placeholder:text-inherit"
          resolver={(arg: any, text) => {
            let argl = arg as IListingSummary
            let match = text.toLowerCase()
            return (argl.mlsID && argl.mlsID.toLowerCase().includes(match)) ||
              (argl.address.text &&
                argl.address.text.toLowerCase().includes(match)) ||
              (argl.majorArea && argl.majorArea.toLowerCase().includes(match)) ||
              (argl.minorArea && argl.minorArea.toLowerCase().includes(match)) ||
              (argl.subdivision &&
                argl.subdivision.toLowerCase().includes(match)) ||
              (argl.neighborhood &&
                argl.neighborhood.toLowerCase().includes(match))
              ? true
              : false
          }}
        />
        <span className="text-composite-text not-italic bold">{"OR"}</span>
        <div className="flex flex-col xl:flex-row flex-nowrap gap-5">
          <Check
            context={context}
            label="Our Properties Only"
            inputClassName=" bg-transparent"
            param="ours"
            className="text-composite-text"
            resolver={(arg: any) => {
              let argl = arg as IListingSummary
              return argl.exclusive || false
            }}
          />
          <Check
            context={context}
            label="Hide Under Contract"
            inputClassName=" bg-transparent"
            param="hidePending"
            className="text-composite-text"
            resolver={(arg: any) => {
              let argl = arg as IListingSummary
              return argl.status !== "Pending"
            }}
          />
        </div>
      </>
    )
  }

  const compactSearchFilters = function (context: ISearchContext) {
    /**
   *
   */
    const baseDropdownProps: Partial<IFilterDropdown> = {
      fieldsBoxClassName: "bg-body-bg",
      buttonClassName: "!text-base !text-body-text-inverted after:!border-body-text-inverted",
    }

    return (
      <div className="sm:col-span-2 lg:col-span-4 flex flex-col items-start lg:flex-row lg:justify-between lg:items-center gap-3">
        <div className="border inline-flex items-center border-body-text-inverted rounded">
          <Text
            context={context}
            placeholder="Address or MLS#"
            param="text"
            label="Address or MLS#"
            inputClassName="w-60 !border-0 bg-transparent text-body-text-inverted placeholder:text-body-text-inverted"
            className="col-span-3"
            resolver={(arg: any, text) => {
              let argl = arg as IListingSummary
              let match = text.toLowerCase()
              return (argl.mlsID && argl.mlsID.toLowerCase().includes(match)) ||
                (argl.address.text &&
                  argl.address.text.toLowerCase().includes(match)) ||
                (argl.majorArea && argl.majorArea.toLowerCase().includes(match)) ||
                (argl.minorArea && argl.minorArea.toLowerCase().includes(match)) ||
                (argl.subdivision &&
                  argl.subdivision.toLowerCase().includes(match)) ||
                (argl.neighborhood &&
                  argl.neighborhood.toLowerCase().includes(match))
                ? true
                : false
            }}
          />
          <div className="w-12 p-4 border-l border-body-text-inverted [&>svg]:!text-body-text-inverted">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M10.3333 9.33333H9.80667L9.62 9.15333C10.2733 8.39333 10.6667 7.40667 10.6667 6.33333C10.6667 3.94 8.72667 2 6.33333 2C3.94 2 2 3.94 2 6.33333C2 8.72667 3.94 10.6667 6.33333 10.6667C7.40667 10.6667 8.39333 10.2733 9.15333 9.62L9.33333 9.80667V10.3333L12.6667 13.66L13.66 12.6667L10.3333 9.33333ZM6.33333 9.33333C4.67333 9.33333 3.33333 7.99333 3.33333 6.33333C3.33333 4.67333 4.67333 3.33333 6.33333 3.33333C7.99333 3.33333 9.33333 4.67333 9.33333 6.33333C9.33333 7.99333 7.99333 9.33333 6.33333 9.33333Z" fill="#FFFFFF" />
            </svg>
          </div>
        </div>
        <FilterDropdown
          classNames="w-full grow"
          title="Filters"
          fieldsBoxClassName="!w-full !max-w-full !shadow-none lg:!p-0 !relative"
          deactivationBreakpoint={EActivationBreakpoint.lg}
          buttonClassName="!text-base !text-body-text-inverted after:!border-body-text-inverted"
        >
          <div className="w-full flex flex-col lg:flex-row lg:justify-between lg:items-center gap-y-2">
            <div className="flex flex-col lg:flex-row gap-x-7 gap-y-2">
              <FilterDropdown
                title="Location"
                buttonClassName="inverted"
                children={(
                  <div className="text-body-text flex flex-col">
                    <span className="font-bold mb-2">Location</span>
                    <div className="grid grid-cols-1">
                      <Choose
                        context={context}
                        placeholder="Location"
                        label="Location"
                        param="address.city"
                        resolver={arg => arg.address.city}
                        match="any"
                        reactSelectStyles={selectStylesCompact}
                        showAllEntriesOption={true}
                        options={[
                          {
                            value: "Aspen",
                            label: "Aspen",
                          },
                          {
                            value: "Snowmass Village",
                            label: "Snowmass Village",
                          },
                          {
                            value: "Basalt",
                            label: "Basalt",
                          },
                          {
                            value: "Carbondale",
                            label: "Carbondale",
                          },
                          {
                            value: "Snowmass",
                            label: "Snowmass",
                          },
                          {
                            value: "Woody Creek",
                            label: "Woody Creek",
                          },
                          {
                            value: "Glenwood Springs",
                            label: "Glenwood Springs",
                          },
                        ]}
                      />
                    </div>
                  </div>
                )}
                {...baseDropdownProps}
              />
              <FilterDropdown
                title="Type"
                children={(
                  <div className="text-body-text flex flex-col">
                    <span className="font-bold mb-2">Type</span>
                    <div className="flex flex-row">
                      <Choose
                        context={context}
                        placeholder="Type"
                        label="Type"
                        param="type"
                        resolver={arg => arg.type}
                        sort="count"
                        match="any"
                        reactSelectStyles={selectStylesCompact}
                      />
                    </div>
                  </div>
                )}
                {...baseDropdownProps}
              />
              <FilterDropdown
                title="Sub-Type"
                children={(
                  <div className="text-body-text flex flex-col">
                    <span className="font-bold mb-2">Sub-Type</span>
                    <div className="flex flex-row">
                      <Choose
                        context={context}
                        placeholder="Sub-Type"
                        label="Sub-Type"
                        param="subType"
                        resolver={arg => arg.subType}
                        sort="count"
                        match="any"
                        reactSelectStyles={selectStylesCompact}
                      />
                    </div>
                  </div>
                )}
                {...baseDropdownProps}
              />
              <FilterDropdown
                title="Price"
                children={(
                  <div className="text-body-text flex flex-col">
                    <span className="font-bold mb-2">Price</span>
                    <div className="flex justify-start gap-x-4">{priceFilters(context, selectStylesCompact)}</div>
                  </div>
                )}
                {...baseDropdownProps}
              />
              <FilterDropdown
                title="Features"
                children={(
                  <div className="text-body-text flex flex-col">
                    <span className="font-bold mb-2">Features</span>
                    <div className="flex flex-row">
                      <Choose
                        context={context}
                        placeholder="Features"
                        label="Features"
                        param="features"
                        resolver={arg => arg.features}
                        sort="count"
                        match="one"
                        reactSelectStyles={selectStylesCompact}
                      />
                    </div>
                  </div>
                )}
                {...baseDropdownProps}
              />
              <FilterDropdown
                title="More..."
                classNames="hidden lg:block xl:hidden"
                children={(
                  <div className="text-body-text flex flex-col">
                    <div className="flex flex-col gap-3">
                      <Check
                        context={context}
                        label="Our Properties Only"
                        inputClassName="bg-transparent"
                        param="ours"
                        className="text-body-text"
                        resolver={(arg: any) => {
                          let argl = arg as IListingSummary
                          return argl.exclusive || false
                        }}
                      />
                      <Check
                        context={context}
                        label="Hide Under Contract"
                        inputClassName="bg-transparent"
                        param="hidePending"
                        className="text-body-text"
                        resolver={(arg: any) => {
                          let argl = arg as IListingSummary
                          return argl.status !== "Pending"
                        }}
                      />
                    </div>
                  </div>
                )}
                {...baseDropdownProps}
              />
              <Check
                context={context}
                label="Our Properties Only"
                inputClassName="bg-transparent"
                param="ours"
                className="text-composite-text lg:hidden xl:block"
                resolver={(arg: any) => {
                  let argl = arg as IListingSummary
                  return argl.exclusive || false
                }}
              />
              <Check
                context={context}
                label="Hide Under Contract"
                inputClassName="bg-transparent"
                param="hidePending"
                className="text-composite-text lg:hidden xl:block"
                resolver={(arg: any) => {
                  let argl = arg as IListingSummary
                  return argl.status !== "Pending"
                }}
              />
            </div>
          </div>
        </FilterDropdown>
        <Choose
          context={context}
          placeholder="Status"
          label="status"
          param="statuses"
          resolver={arg => arg.status}
          match="any"
          className="hidden"
        />
      </div>
    )
  }

  const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

  const peopleSort = function (context: ISearchContext) {
    const currentFilterValues: any = context?.currentFilterValues
    return (
      <>
        <div className="w-full max-w-screen-lg flex flex-row flex-wrap lg:flex-nowrap justify-center gap-y-2 pt-16 pb-14">
          <span className="uppercase text-xs w-24 text-neutral-500 tracking-widest">
            SEARCH BY LAST NAME
          </span>
          <ul className="flex flex-row flex-wrap lg:flex-nowrap justify-center lg:justify-start gap-y-2">
            {alphabet.map((item, index) => (
              <li key={index} className={`px-2.5 border-b pb-4 ${currentFilterValues?.letter == item ? "border-accent border-b-8" : "border-neutral-500"}`}>
                <a onClick={() => { context.setFilterValue!("letter", item) }} key={index} className="text-neutral-500 cursor-pointer">
                  {item}
                </a>
              </li>
            ))}
            <li className={`px-2.5 border-b pb-4 ${!currentFilterValues?.letter ? "border-accent border-b-8" : "border-neutral-500"}`}>
              <a onClick={() => { context.setFilterValue!("letter", "") }} className="text-neutral-500 cursor-pointer">
                ALL
              </a>
            </li>
          </ul>
        </div>
      </>
    )
  }

  const letterFilter = function (context: ISearchContext) {
    const letterOptions = alphabet.map((item) => {
      return {
        label: item,
        value: item
      }
    })
    letterOptions.push({
      label: "All",
      value: ""
    })

    return (
      <Choose
        className="hidden"
        context={context}
        label="Letter"
        placeholder="Letter"
        customFilter={(arg, value) => {
          let argl = arg as IAgent
          let vstr = value as string
          vstr = vstr.toLowerCase()
          return argl.last &&
            argl.last.toLowerCase().startsWith(vstr) ? true : false
        }}
        param="letter"
        resolver={arg => arg.price}
        reactSelectStyles={selectStyles}
        options={letterOptions}
      />
    )
  }

  /**
   * Standard filters for global Real Estate people search.
   */
  const peopleStandardFilters = function (context: ISearchContext) {
    return (
      <>
        <Text
          context={context}
          placeholder="SEARCH BY LOCATION"
          param="location"
          label="SEARCH BY LOCATION"
          inputClassName="w-full bg-transparent text-composite-text border-composite-text placeholder:text-inherit"
          className="col-span-4"
          resolver={(arg: any, text) => {
            let argl = arg as any
            let match = text.toLowerCase()
            let regex = new RegExp(match, 'gi')
            return argl.location?.some((l: string) => l?.match(regex))
          }}
        />
        {letterFilter(context)}
      </>
    )
  }

  const PrevArrow = (props: any) => {
    return (
      <button
        {...props}
        className={`${props.className} carousel-prev-arrow z-10 before:!hidden !w-[30px] xl:!w-[45px] 2xl:!w-[60px]`}
      >
        <svg viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="30" cy="30" r="29.5" transform="matrix(-1 0 0 1 60.25 0)" stroke="white" />
          <path d="M26.1472 30.5966L32.5028 37.4272C32.8094 37.7567 33.3063 37.7567 33.6128 37.4272L34.3541 36.6305C34.6601 36.3017 34.6607 35.7687 34.3554 35.439L29.3184 30.0001L34.3554 24.5612C34.6607 24.2315 34.6601 23.6985 34.3541 23.3696L33.6128 22.5729C33.3063 22.2435 32.8093 22.2435 32.5028 22.5729L26.1472 29.4036C25.8407 29.733 25.8407 30.2671 26.1472 30.5966Z" fill="white" />
        </svg>
      </button>
    );
  }
  const NextArrow = (props: any) => {
    return (
      <button
        {...props}
        className={`${props.className} carousel-next-arrow z-10 before:!hidden !w-[30px] xl:!w-[45px] 2xl:!w-[60px]`}
      >
        <svg viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="30.25" cy="30" r="29.5" stroke="white" />
          <path d="M34.3533 30.5964L27.9976 37.4271C27.6911 37.7566 27.1942 37.7566 26.8877 37.4271L26.1464 36.6304C25.8404 36.3016 25.8398 35.7685 26.1451 35.4389L31.182 30L26.1451 24.561C25.8398 24.2314 25.8404 23.6984 26.1464 23.3695L26.8877 22.5728C27.1942 22.2434 27.6912 22.2434 27.9976 22.5728L34.3533 29.4035C34.6598 29.7329 34.6598 30.267 34.3533 30.5964Z" fill="white" />
        </svg>
      </button>
    )
  }

  const StatsPrevArrow = (props: any) => {
    return (
      <button
        {...props}
        className={`${props.className} before:!hidden !-left-[5%] 2xl:!-left-[60px] !h-auto !w-[5%] 2xl:!w-[60px]`}
      >
        <svg viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="30" cy="30" r="29.5" transform="matrix(-1 0 0 1 60.25 0)" stroke="white" />
          <path d="M26.1472 30.5966L32.5028 37.4272C32.8094 37.7567 33.3063 37.7567 33.6128 37.4272L34.3541 36.6305C34.6601 36.3017 34.6607 35.7687 34.3554 35.439L29.3184 30.0001L34.3554 24.5612C34.6607 24.2315 34.6601 23.6985 34.3541 23.3696L33.6128 22.5729C33.3063 22.2435 32.8093 22.2435 32.5028 22.5729L26.1472 29.4036C25.8407 29.733 25.8407 30.2671 26.1472 30.5966Z" fill="white" />
        </svg>
      </button>
    );
  }

  const StatsNextArrow = (props: any) => {
    return (
      <button
        {...props}
        className={`${props.className} before:!hidden !-right-[5%] 2xl:!-right-[60px] !h-auto !w-[5%] 2xl:!w-[60px]`}
      >
        <svg viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="30.25" cy="30" r="29.5" stroke="white" />
          <path d="M34.3533 30.5964L27.9976 37.4271C27.6911 37.7566 27.1942 37.7566 26.8877 37.4271L26.1464 36.6304C25.8404 36.3016 25.8398 35.7685 26.1451 35.4389L31.182 30L26.1451 24.561C25.8398 24.2314 25.8404 23.6984 26.1464 23.3695L26.8877 22.5728C27.1942 22.2434 27.6912 22.2434 27.9976 22.5728L34.3533 29.4035C34.6598 29.7329 34.6598 30.267 34.3533 30.5964Z" fill="white" />
        </svg>
      </button>
    );
  }

  const TestimonialPrevArrow = (props: any) => {
    return (
      <button
        {...props}
        className={`${props.className.replaceAll("slick-prev", "")} row-start-1 row-end-2 col-start-3 col-end-4 pt-2 order-1 text-body-text testimonial-prev-arrow z-10 before:!hidden`}
      >
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="30" cy="30" r="29.5" transform="matrix(-1 0 0 1 60 0)" stroke="#002349" />
          <path d="M33.3449 37.4106L33.3449 37.4106L34.0862 36.6139C34.3835 36.2944 34.3841 35.7759 34.0875 35.4556L29.0505 30.0167L29.0352 30.0001L29.0505 29.9835L34.0875 24.5446C34.3841 24.2243 34.3835 23.7058 34.0862 23.3863L33.3449 22.5896C33.0481 22.2705 32.5676 22.2705 32.2707 22.5896L32.2528 22.5729L32.2707 22.5896L25.9151 29.4202C25.6173 29.7403 25.6173 30.2599 25.9151 30.5799L32.2707 37.4106C32.5676 37.7297 33.0481 37.7297 33.3449 37.4106Z" fill="#002349" stroke="#002349" stroke-width="0.0488281" />
        </svg>
      </button>
    );
  }
  const TestimonialNextArrow = (props: any) => {
    return (
      <button
        {...props}
        className={`${props.className.replaceAll("slick-next", "")} row-start-1 row-end-2 col-start-5 col-end-6 pt-2 order-2 testimonial-next-arrow z-10 before:!hidden`}
      >
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="30" cy="30" r="29.5" stroke="#002349" />
          <path d="M26.6555 37.4104L26.6555 37.4104L25.9143 36.6137C25.617 36.2942 25.6164 35.7756 25.913 35.4554L30.95 30.0164L30.9653 29.9998L30.95 29.9832L25.913 24.5443C25.6164 24.2241 25.617 23.7055 25.9143 23.386L26.6555 22.5893C26.9524 22.2703 27.4329 22.2703 27.7298 22.5893L27.7476 22.5727L27.7298 22.5893L34.0854 29.42C34.3832 29.74 34.3832 30.2596 34.0854 30.5797L27.7298 37.4104C27.4329 37.7294 26.9524 37.7294 26.6555 37.4104Z" fill="#002349" stroke="#002349" stroke-width="0.0488281" />
        </svg>
      </button>
    )
  }
  
  const footerChildren = (
    <div className="flex flex-col w-full relative mt-14 inverted">
      <div className="relative bg-body-bg-inverted max-w-none w-full flex flex-col items-center p-0">
        <div className="flex flex-wrap xl:flex-nowrap w-full mx-0 px-6 pb-6 md:px-0 md:py-10 md:max-w-xl xl:max-w-screen-xl justify-center items-center">
          <div className="lg:max-w-none md:w-4/12 xl:w-2/12">
            <div className="relative w-full m-0 flex flex-col justify-center items-center">
              {data.config.headshot?.url && <img className="w-[165px] -mt-6 md:mt-0 md:absolute" src={data.config.headshot.url} alt="" width="184" height="237" />}
            </div>
          </div>
          <a aria-current="page" className="w-full md:w-8/12 xl:w-4/12 lg:max-w-none pb-0 xl:pl-10">
            <span className="sr-only">Homepage</span>
            {data.config.footerLogo?.url && <img className="w-full" src={data.config.footerLogo?.url} width="416" height="80" alt="Chris Klug Properties" />}
          </a>
          <div className="w-full xl:w-3/12 lg:max-w-none flex pt-4 md:pt-24 xl:pt-0">
            <div className="flex flex-wrap justify-center w-full pb-2 text-4xl">
              {data.config.socialLinks?.facebook && <a className="p-3 text-body-text-inverted" href={data.config.socialLinks.facebook.url} title="Chris Klug Properties on Facebook" target="_blank"><FaFacebookF /></a>}
              {data.config.socialLinks?.instagram && <a className="p-3 text-body-text-inverted" href={data.config.socialLinks.instagram.url} title="Chris Klug Properties on Instagram" target="_blank"><FaInstagram /></a>}
              {data.config.socialLinks?.twitter && <a className="p-3 text-body-text-inverted" href={data.config.socialLinks.twitter.url} title="Chris Klug Properties on Twitter" target="_blank"><FaTwitter /></a>}
              {data.config.socialLinks?.youTube && <a className="p-3 text-body-text-inverted" href={data.config.socialLinks.youTube.url} title="Chris Klug Properties on YouTube" target="_blank"><FaYoutube /></a>}
              {data.config.socialLinks?.vimeo && <a className="p-3 text-body-text-inverted" href={data.config.socialLinks.vimeo.url} title="Chris Klug Properties on Vimeo" target="_blank"><FaVimeo /></a>}
              {data.config.socialLinks?.pinterest && <a className="p-3 text-body-text-inverted" href={data.config.socialLinks.pinterest.url} title="Chris Klug Properties on Pinterest" target="_blank"><FaPinterest /></a>}
              {data.config.socialLinks?.linkedIn && <a className="p-3 text-body-text-inverted" href={data.config.socialLinks.linkedIn.url} title="Chris Klug Properties on LinkedIn" target="_blank"><FaLinkedinIn /></a>}
            </div>
          </div>
          <div className="w-1/2 md:w-1/4 lg:w-auto xl:w-1/4 lg:max-w-none">
            {data.config.footerSecondaryLogo?.icon?.url && <img src={data.config.footerSecondaryLogo.icon.url} alt="" />}
          </div>
        </div>
      </div>
    </div>
  )

  const locationOrigin = typeof window !== "undefined" ? window.location.origin : "";
  const siteMenuItems: ISiteMenuItem[] = [
    {
      isFolder: true,
      menuText: "Listings",
      children: [
        {
          isFolder: false,
          menuText: "Exclusive And New",
          path: `${locationOrigin}/Listings/exclusive-and-new`,
        },
        {
          isFolder: false,
          menuText: "Closed Listings",
          path: `${locationOrigin}/Listings/closed-listings`,
        },
        {
          isFolder: false,
          menuText: "Off Market Listings",
          path: `${locationOrigin}/Listings/off-market-listings`,
        },
      ]
    },
    {
      isFolder: false,
      menuText: "Search",
      path: `${locationOrigin}/search`,
    },
    {
      isFolder: true,
      menuText: "Communities",
      children: [
        {
          isFolder: true,
          menuText: "Communities",
          children: [
            {
              isFolder: false,
              menuText: "Aspen",
              path:`${locationOrigin}/communities/aspen`
            },
            {
              isFolder: false,
              menuText: "Snowmass Village",
              path:`${locationOrigin}/communities/snowmass-village`
            },
            {
              isFolder: false,
              menuText: "Old Snowmass",
              path:`${locationOrigin}/communities/old-snowmass`
            },
            {
              isFolder: false,
              menuText: "Woody Creek",
              path:`${locationOrigin}/communities/woody-creek`
            },
            {
              isFolder: false,
              menuText: "Basalt",
              path:`${locationOrigin}/communities/basalt`
            },
            {
              isFolder: false,
              menuText: "Carbondale",
              path:`${locationOrigin}/communities/carbondale`
            },
          ]
        },
        {
          isFolder: true,
          menuText: "Neighborhoods",
          children: [
            {
              isFolder: false,
              menuText: "Red Mountain",
              path:`${locationOrigin}/communities/red-mountain#showMap=true`
            },
            {
              isFolder: false,
              menuText: "East Aspen",
              path:`${locationOrigin}/communities/east-aspen#showMap=true`
            },
            {
              isFolder: false,
              menuText: "Smuggler",
              path:`${locationOrigin}/communities/smuggler#showMap=true`
            },
            {
              isFolder: false,
              menuText: "West Aspen",
              path:`${locationOrigin}/communities/west-aspen#showMap=true`
            },
            {
              isFolder: false,
              menuText: "West End",
              path:`${locationOrigin}/communities/west-end#showMap=true`
            },
            {
              isFolder: false,
              menuText: "Aspen Core",
              path:`${locationOrigin}/communities/aspen-core#showMap=true`
            },
            {
              isFolder: false,
              menuText: "McLain Flats",
              path:`${locationOrigin}/communities/mclain-flats#showMap=true`
            },
          ]
        },
        {
          isFolder: true,
          menuText: "Locations",
          children: [
            {
              isFolder: false,
              menuText: "Cirque Residences",
              path:`${locationOrigin}/All-Communities/locations/Cirque-Snowmass`
            },
            {
              isFolder: false,
              menuText: "Aspen Square",
              path:`${locationOrigin}/All-Communities/locations/aspen-square`
            },
          ]
        },
      ]
    },
    {
      isFolder: false,
      menuText: "Market Report",
      path: `${locationOrigin}/aspen-snowmass-market-reports`,
    },
    {
      isFolder: true,
      menuText: "Media",
      children: [
        {
          isFolder: false,
          menuText: "Videos",
          path: `${locationOrigin}/media/videos`, 
        },
        {
          isFolder: false,
          menuText: "Living Aspen Magazine",
          path: `${locationOrigin}/media/living-aspen-magazine`, 
        }
      ]      
    },
    {
      isFolder: true,
      menuText: "About",
      children: [
        {
          isFolder: false,
          menuText: "Why Klug Properties",
          path: `${locationOrigin}/about/why-klug-properties`, 
        },
        {
          isFolder: false,
          menuText: "Testimonials",
          path: `${locationOrigin}/about/testimonials`, 
        },
        {
          isFolder: false,
          menuText: "Blog",
          path: `${locationOrigin}/about/blog`, 
        },
        {
          isFolder: false,
          menuText: "Our Team",
          path: `${locationOrigin}/about/our-team`, 
        },
        {
          isFolder: false,
          menuText: "Partners",
          path: `${locationOrigin}/about/partners`, 
        },
        {
          isFolder: false,
          menuText: "Ski Town Partners",
          path: `${locationOrigin}/about/ski-town-partners`, 
        },
      ]      
    },
  ]

  /**
   * Wrap our pages in the SiteContext to provide configuration and
   * customization of various site elements.  See all usages of useSiteContext()
   * in @organic-return/foundation-gatsby-plugin-agility
   */
  return (
    <SiteContext.Provider
      value={{        
        listingLayoutProps: {
          vitalsProps: {
            className: "mb-3 inverted",
          },
          detailsProps: {
            className: "max-w-screen-xl mx-auto p-4",
            title: "",
          },
          summaryProps: {
            className: "max-w-screen-xl mx-auto p-4",
          },
          formProps: {
            classNames: "inverted strictTwoColumn textarea-body-text-color",
            title: "Request Information",
            inputTextAreaLabel: "Add a message here...",
          },
          rowClassName: "max-w-screen-xl mx-auto",
          showContactUsForm: true,
          contactUsFormProps: {
            checkboxGroups: {
              label: "I am interested in the following",
              groups: [
                { list: ["Aspen Square", "Having a broker contact me"] },
                {
                  list: [
                    "Aspen",
                    "Snowmass Village",
                    "Basalt",
                    "Carbondale",
                    "Snowmass",
                    "Woody Creek",
                    "Glenwood Springs",
                  ],
                },
              ],
            },
          },
          galleryProps: {
            showCaptions: false
          }
        },
        exclusiveLayoutProps: {
          vitalsProps: {
            className: "mb-3 inverted",
          },
          detailsProps: {
            className: "max-w-screen-xl mx-auto p-4",
            title: "",
          },
          formProps: {
            classNames: "inverted strictTwoColumn textarea-body-text-color",
            title: "Request Information",
            inputTextAreaLabel: "Add a message here...",
          },
          rowClassName: "max-w-screen-xl mx-auto",
          showContactUsForm: true,
          contactUsFormProps: {
            checkboxGroups: {
              label: "I am interested in the following",
              groups: [
                { list: ["Aspen Square", "Having a broker contact me"] },
                {
                  list: [
                    "Aspen",
                    "Snowmass Village",
                    "Basalt",
                    "Carbondale",
                    "Snowmass",
                    "Woody Creek",
                    "Glenwood Springs",
                  ],
                },
              ],
            },
          },
          documentsProps: {
            title: "Documents",
            className: "max-w-screen-xl mx-auto p-4",
          },
          galleryProps: {
            showCaptions: false
          }
        },
        rentalLayoutProps: {
          vitalsProps: {
            className: "mb-3 inverted",
          },
          className: "w-auto",
          detailsProps: {
            className: "max-w-screen-xl mx-auto",
          },
          rowClassName: "max-w-screen-xl mx-auto",
          formProps: {
            classNames: "inverted strictTwoColumn textarea-body-text-color",
            title: "Request Information",
            inputTextAreaLabel: "Add a message here...",
          },
        },
        listingSearchProps: {
          primaryFilters: listingStandardFilters,
          secondaryFilters: listingMiddleFilters,
          sort: listingSort,
          filterClassName: "inverted",
          headerClassName: "max-w-screen-xl mx-auto",
          pagerClassName: "max-w-screen-xl mx-auto",
          mapClassName: "w-99 mx-auto",
          resultsClassName: "max-w-screen-2xl mx-auto",
        },
        listingEntrySearchProps: {
          title: "Search",
          standardFilters: listingEntryFilters,
          orFilters: orFilters,
          buttonClassName: "hover:bg-button-bg-inverted !border-composite-text !text-composite-text !py-2 !px-8",
          buttonText: "GO",
        },
        communitySearchProps: {
          primaryFilters: communityStandardFilters,
          secondaryFilters: listingMiddleFilters,
          filterClassName: "inverted",
          headerClassName: "max-w-screen-xl mx-auto",
          pagerClassName: "max-w-screen-xl mx-auto",
          resultsClassName: "max-w-screen-2xl mx-auto",
          mapClassName: "max-w-screen-2xl mx-auto",
          sort: listingSort,
        },
        peopleSearchProps: {
          headerClassName: "max-w-screen-lg mx-auto",
          pagerClassName: "max-w-screen-lg mx-auto",
          resultsClassName: "max-w-screen-xl mx-auto",
          className: "inverted",
          showCount: false,
        },
        partnersListingProps: {
          primaryFilters: peopleStandardFilters,
          sort: peopleSort,
        },
        globalListingCarouselProps: {
          className: "min-h-screen [&>a]:my-14",
          slickSettings: {
            prevArrow: <PrevArrow />,
            nextArrow: <NextArrow />,
          },
          title: undefined,
        },
        globalListingCarouselCardProps: {
          layout: EListingCardLayouts.composite,
          vitalsLayout: EListingVitalsLayout.column1VitalsRows,
          vitalsProps: {
            className: "gap-y-2 md:gap-y-3 lg:gap-y-4 xl:gap-y-7",
            vitalIconsClassName: "gap-y-2 md:gap-y-3 lg:gap-y-4 xl:gap-y-7 pb-2 md:pb-3 lg:pb-4 xl:pb-7"
          },
          vitalsLocationIcon: false,
          vitalsPriceTagIcon: true,
          vitalsTwoLineAddress: true,
          vitalsIncludeVitalsIcons: true,
          linkText: "View Listing",
          linkVitals: false,
          customAddressFormat: (listing) => {
            let state = listing.address.state
            if (state === "CO") {
              state = "Colorado"
            }
            return (
              <div className="flex flex-col gap-y-2 md:gap-y-3 lg:gap-y-4 xl:gap-y-7">
                <span className="text-4xl uppercase">{`${listing.address.city ?? ""}${listing.address.city && listing.address.state ? " - " : ""}${state ?? ""}`}</span>
                <span className="text-2xl font-normal">{listing.address.text}</span>
              </div>
            )
          },
          imageProps: {
            lazy: true,
            imgProps: {
              className:
                "w-full h-auto h-full object-cover object-center",
              wrapperClassName: "w-full h-screen lg:h-auto lg:aspect-[4/3] xl:aspect-[4/3] 2xl:aspect-[32/19]",
              wrapperProps: {
                style: {
                  display: "block"
                }
              }
            }
          }
        },
        globalListingCardProps: {
          vitalsTwoLineAddress: true,
          vitalsLocationIcon: false,
          vitalsLayout: EListingVitalsLayout.column1Border0
        },
        headerProps: {
          addFlagOnScroll: true
        },
        footerLayout: FooterLabeledColumns,
        footerProps: {
          children: footerChildren,
          showAttribution: true,
          copyright: data.config.copyright,
          colsTitles: data.config.columnsTitles,
          htmlCols: data.config.footerContent1 ? [data.config.footerContent1] : [],
          formProps: {
            classNames: "singleField",
            fieldsetClassName: "!p-0",
            mainLegendClassName: "hidden",
            mainListClassName: "!my-0",
            unwantedTextInputs: ["firstName", "lastName", "phoneNumber"],
            hideReCaptchaText: true,
          },
          socialMediaLinks: [],
          segmentWriteKey: process.env.GATSBY_SEGMENT_ANALYTICS_KEY,
          segmentConsentManagerProps: {
            bannerContent: (
              <span>
                We use cookies (and other similar technologies) to collect data to improve your experience on
                our site. By using our website, you’re agreeing to the collection of data as described in our{' '}
                <a href="/privacy" target="_blank">
                  Privacy Policy
                </a>
                .
              </span>
            ),
            consentManagerProps: {
              customCategories: {
                'Marketing and Analytics': {
                  purpose: "To understand user behavior in order to provide you with a more relevant browsing experience or personalize the content on our site. For example, we collect information about which pages you visit to help us present more relevant information.",
                  integrations: ['Foundation CRM Leads (Organicreturn)'],
                }
              }
            }
          },
        },
        communityLayoutProps: {
          isTitleInHeading: true
        },
        listingSearchCompactProps: {
          primaryFilters: compactSearchFilters,
          sort: listingSort,
          filterClassName: "text-white py-2.5 bg-body-bg-inverted px-2 lg:px-4",
          formClassName: "!max-w-screen-2xl",
          pagerClassName: "max-w-screen-xl mx-auto",
          mapClassName: "w-full",
          resultsClassName: "mx-auto",
          className: "compact-search-module mb-16",
          defaultSort: "onMktTSDESC",
        },
        globalPartnerMapSearchProps: {
          markerProps: {
            icon: {
              path: 0,
              scale: 7,
              strokeColor: "white",
            }
          },
          initialCenter: {
            lat: 33.7753832,
            lng: -38.2961321,
          },
          searchBoxTitleClassName: "!text-4xl"
        },
        globalInfoBoxWithGridProps: {
          sectionOverlayClassNames: "bg-[rgba(18,39,90,.6)]",
        },
        globalMarketStatsWithSliderProps: {
          titleClassNames: "text-center lg:text-right",
          collectionClassNames: "text-body-text-inverted hover:text-body-text-inverted uppercase",
          activeCollectionClassNames: "text-body-text-inverted hover:text-body-text-inverted uppercase",
          marketClassNames: "!bg-white/80 text-black font-medium",
          activeMarketClassNames: "!bg-accent text-black font-medium",
          statCardClassNames: "text-black border-4 border-body-bg-inverted md:min-h-60",
          contentColClassNames: "text-black border-4 border-body-bg-inverted mkt-stats-content-col",
          sliderSettings: {
            prevArrow: <StatsPrevArrow />,
            nextArrow: <StatsNextArrow />
          },
        },
        globalTestimonialSliderProps: {
          headingClass: "lg:col-start-1 lg:col-end-6 text-5xl lg:text-right",
          className: "!px-0 !py-0 w-full max-w-[1200px] flex flex-col lg:grid lg:grid-cols-18 xl:grid-cols-20 gap-y-8 lg:gap-y-11",
          columnsWrapperClass: "w-full lg:row-start-2 lg:row-end-3 lg:col-start-1 lg:col-end-19 xl:col-end-21",
          leftColumnClass: "hidden",
          dividerClass: "hidden",
          rightColumnClass: "w-full",
          testimonialTextClass: "grow",
          showQuotes: false,
          showAttribution: true,
          attributionClass: "text-right not-italic",
          slickSettings: {
            arrows: true,
            className: "!flex flex-wrap justify-center gap-8 lg:gap-0 w-full lg:!grid lg:grid-cols-18 xl:grid-cols-20 items-start lg:[&>.slick-list]:col-start-9 lg:[&>.slick-list]:col-end-19 xl:[&>.slick-list]:col-end-21",
            prevArrow: <TestimonialPrevArrow />,
            nextArrow: <TestimonialNextArrow />
          },
        },
        siteMenuItems: siteMenuItems,
      }}
    >
      {children}
    </SiteContext.Provider>
  )
}
